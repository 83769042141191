import React from 'react';

const Lore = () => {
    return (
        <>
            <p>
                Ladies and Gentlemen, we welcome you to the <b>first ever classical artwork mint</b> on <b>Bitcoin</b>.
            </p>
            <p>
                Minters will receive a <b>random selection</b> from a <b>curated collection of 300</b> of the greatest all-time pieces. The Ordchard is abundant with <b>beautiful, time transcendent works</b> ranging from Michaelangelo to Picasso to ancient Chinese. Have a spin to quench your classical zest and perhaps land <b>1 of the 2 bitcoinfied inscriptions</b> in your basket at the <b>Classical Ordchard</b>. This is a once in a lifetime opportunity to inscribe art, that traditionally lines museum hallways, on the timechain that will persist for eons to come.
            </p>
            <p>
                Need we say more? Enjoy your pickings while they last.
            </p>
        </>
    )
}

export default Lore