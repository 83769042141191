import React, { useState, useRef, useEffect } from 'react';
import './App.css';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaRegCopy } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import { TailSpin } from 'react-loading-icons'
import { QRCodeSVG } from 'qrcode.react';
import Countdown from 'react-countdown';
import useInterval from 'react-useinterval';
import TitleImage from './assets/images/title.png'
import config from '../config.json'
import Lore from './Lore'
import { validate, Network } from 'bitcoin-address-validation';
import { nip19 } from 'nostr-tools'
import * as bitcoin from 'bitcoinjs-lib'
import * as ecc from 'tiny-secp256k1'
bitcoin.initEccLib(ecc)
const axios = require('axios')

const TESTNET = false
const RECOMMENDED_FEE_RATE = '12'
const COLLECTION_ID = TESTNET ? 'e23961e869b621b3fb33286b118c59a0' : config.collectionId
const API_BASE_URL = `https://api${TESTNET ? '-testnet' : ''}.deezy.io`
const INVOICE_EXPIRY_MS = 120000
const UTXO_BASE_SATS = 10000

function importAll(r) {
  return r.keys().map(r);
}
const imageSrcModules = importAll(require.context('./assets/images/samples', false, /\.(png|jpe?g|svg)$/));

import { SocialIcon } from 'react-social-icons';
import { FadeIn } from 'react-slide-fade-in';
import NavLink from 'react-bootstrap/esm/NavLink';

const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>the invoice has expired</span>;
  } else {
    // Render a countdown
    return <span>expires in: {hours > 0 ? `${hours}h` : ''} {minutes > 0 ? `${minutes}m` : ''} {seconds}s</span>;
  }
}


const App = () => {
  const exploreRef = useRef(null);
  const scrollToRef = (ref) => ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  const [showBeginMintModal, setShowBeginMintModal] = useState(false);
  const [showSelectFeeRateModal, setShowSelectFeeRateModal] = useState(false);
  const [showPromptForAddressModal, setShowPromptForAddressModal] = useState(false);
  const [mintFeeRate, setMintFeeRate] = useState(RECOMMENDED_FEE_RATE);
  const [numToMint, setNumToMint] = useState(1);
  const [showCustomAddressModal, setShowCustomAddressModal] = useState(false);
  const [isBtcInputAddressValid, setIsBtcInputAddressValid] = useState(true);
  const [destinationBtcAddress, setDestinationBtcAddress] = useState('');
  const [showConfirmMintModal, setShowConfirmMintModal] = useState(false);
  const [customAddressUsed, setCustomAddressUsed] = useState(false)
  const [nostrPublicKey, setNostrPublicKey] = useState('')
  const [showAwaitingInvoiceModal, setShowAwaitingInvoiceModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [invoiceToPay, setInvoiceToPay] = useState("")
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [mintAttemptId, setMintAttemptId] = useState("")
  const [showPayModal, setShowPayModal] = useState(false)
  const [showQrCode, setShowQrCode] = useState(false)
  const [showAwaitingMintModal, setShowAwaitingMintModal] = useState(false)
  const [showFinalInfoModal, setShowFinalInfoModal] = useState(false)
  const [mintOutpoints, setMintOutpoints] = useState([])
  const [minted, setMinted] = useState(false)
  const [collectionInfo, setCollectionInfo] = useState({})
  const [showNoneAvailableModal, setShowNoneAvailableModal] = useState(false)
  const [showViewInfoModal, setShowViewInfoModal] = useState(false)

  function shortenStr(str) {
    return str.substring(0, 8) + "..." + str.substring(str.length - 8, str.length)
  }

  function liveCollectionInfo() {
    console.log(collectionInfo)
    return (<>
      <b>Available:</b> {collectionInfo.num_available}
      <br /><br />
      <b>Minted:</b> {collectionInfo.num_minted}
      <br /><br />
      <span className="small-text">* max supply is {collectionInfo.max_supply}</span>
    </>)
  }

  const fetchPaymentStatusLoop = async () => {
    if ((!showPayModal && !showFinalInfoModal) || !mintAttemptId) return
    //console.log(`polling for invoice status`)
    let response = null
    try {
      response = await axios.get(`${API_BASE_URL}/v1/inscriptions/mint?mint_id=${mintAttemptId}`)
    } catch (err) {
      console.error(err)
    }
    if (response && response.data) {
      //console.log(response.data)
      const status = response.data.status
      if (status === 'FAILED') {
        setShowErrorModal(true)
        return
      }
      if (status === 'PAID') {
        setShowPayModal(false)
        setShowFinalInfoModal(true)
      } else if (status === 'MINTED') {
        setShowPayModal(false)
        setMintOutpoints(response.data.mint_outpoints)
        setMinted(true)
        setShowFinalInfoModal(true)
      }
    }
  }
  useInterval(fetchPaymentStatusLoop, 2000)

  const fetchCollectionInfoLoop = async () => {
    let response = null
    try {
      response = await axios.get(`${API_BASE_URL}/v1/inscriptions/collections/info?collection_id=${COLLECTION_ID}`)
    } catch (err) {
      console.error(err)
    }
    if (response && response.data) {
      setCollectionInfo(response.data)
      // console.log(response.data)
    }
  }
  useInterval(fetchCollectionInfoLoop, 10000)
  useEffect(fetchCollectionInfoLoop, [])

  function handleBtcAddressChange(evt) {
    const newaddr = evt.target.value
    if (newaddr === '') {
      setIsBtcInputAddressValid(true)
      return
    }
    if (!validate(newaddr, TESTNET ? Network.testnet : Network.mainnet)) {
      setIsBtcInputAddressValid(false)
      return
    }
    setDestinationBtcAddress(newaddr)
    setShowCustomAddressModal(false)
    setShowConfirmMintModal(true)
    setCustomAddressUsed(true)
  }

  function miningFeeSats() {
    return ((collectionInfo.fee_rate_multiplier || 0) * mintFeeRate * numToMint)
  }

  function totalPriceSats() {
    return Math.round(((collectionInfo.base_price_sats + UTXO_BASE_SATS) * numToMint) + miningFeeSats())
  }

  function totalPriceBtcDisplay() {
    return `${(totalPriceSats() / 100000000)} BTC`
  }

  return (
      <>
        <Navbar id="top-nav" className="pt-3 pb-3 text-center" expand="lg">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link className="black" onClick={() => scrollToRef(exploreRef)}>
                  About
                </Nav.Link>
                {/**
                 <NavLink className="black">
                 FAQ
                 </NavLink>
                 */
                }{ /**
               <NavLink className="black">
               How to Mint
               </NavLink>
               */
              }
                <NavLink className="black" onClick={() => window.open('https://nosft.xyz')}>
                  Wallet
                </NavLink>
              </Nav>
              <Nav id="top-social-links">
                {config.socialLinks.map((socialLink) => {
                  return (
                      <SocialIcon url={socialLink} target="_blank" className="social-icon nav-link" />
                  )
                })
                }
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container bg="light" variant="light" className="main-container d-flex text-center align-items-center justify-content-center">
          <Container className="top-panel">
            <h1 id="title">{config.title}</h1>
            <div>{config.subtitle}</div>
            <Container className="mt-4 d-flex flex-row align-items-center justify-content-center">
              {
                collectionInfo.name ?
                    <>
                      <Button variant="outline-primary" className="mx-2 button" onClick={() => setShowViewInfoModal(true)}>View Info</Button>
                      <Button variant="primary" className="mx-2 button shadowed-orange-small" onClick={
                        () => {
                          if (collectionInfo.num_available === 0) {
                            setShowNoneAvailableModal(true)
                            return
                          }
                          setShowBeginMintModal(true)
                          // setShowPromptForAddressModal(true)
                          setMintOutpoints([])
                          setMinted(false)
                        }
                      }>{
                        collectionInfo.num_available > 0 ?
                            <>Mint ⚡ Now</>
                            :
                            <>Mint ⚡ Soon</>
                      }
                      </Button>
                    </>
                    :
                    <>
                      <TailSpin stroke="#000000" speed={.75} />
                    </>
              }
            </Container>
          </Container>
          <Container id="top-img" className="top-panel d-flex flex-wrap justify-content-center">
            <img style={{ width: '80%', maxWidth: '400px', border: '2px solid black', borderRadius: '20px' }} alt="" src={TitleImage} className="shadowed-orange" />
            { /**
             * imageSrcModules.slice(0, 4).map(srcModule => {
            return (
              <img
                alt=""
                src={srcModule.default}
                className="tile-img shadowed-orange"
              />
            )
          })
             */}
          </Container>
        </Container>
        {/**
         * <Container className="section text-center w-75">
         <Container bg="dark" variant="dark" className="d-flex flex-wrap flex-row text-center align-items-center justify-content-around">
         <FadeIn from="left" delayInMilliseconds={100} triggerOffset={-100}>
         {imageSrcModules.slice(4).map(srcModule => {
              return (
                <img
                  alt=""
                  src={srcModule.default}
                  className="gallery-img shadowed-orange"
                />
              )
            })
            }
         </FadeIn>
         </Container >
         </Container >
         *
         */}

        <Container ref={exploreRef} id="lore-section" className="section mt-5 text-center">
          <Lore />
        </Container>
        <Container className="section d-flex flex-column text-center align-items-center justify-content-center">
          {
            config.bottomTagline ?
                <div><i>{config.bottomTagline}</i></div>
                : <></>
          }
          <Container className="d-flex flex-row text-center align-items-center my-1" style={{
            maxWidth: '800px',
            justifyContent: `center`
          }}>
            {config.socialLinks.map((socialLink) => {
              return (
                  <SocialIcon url={socialLink} target="_blank" className="social-icon" />
              )
            })
            }
          </Container>
          <p className="text-center">
            Like this site? Launch your own Bitcoin-native NFT collection with <a href="https://deezy.io" target="_blank">Deezy</a> ⚡ ❤️
          </p>
          <br />
          <p className="small-text">
            <a href="https://assets.astralbabes.ai/privacy-policy.pdf" target="_blank">Privacy Policy</a> | <a href="https://assets.astralbabes.ai/terms-of-service.pdf" target="_blank">Terms of Service</a>
          </p>
        </Container>
        <Modal show={showBeginMintModal} onHide={() => setShowBeginMintModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>Mint {config.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body p-4">
            <div>
              How many {config.title} would you like to mint?
            </div>
            <div className="my-3">
              <Form.Control type="number" className="w-25 text-center" step={1} max={Math.min(collectionInfo.max_per_mint, collectionInfo.num_available)} min={1} onChange={(evt) => setNumToMint(evt.target.value)} value={numToMint} />
            </div>
            <div>
              <b>Price:</b> {(numToMint * collectionInfo.base_price_sats) / 100000000} BTC + Mining Fee
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowBeginMintModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => {
              setShowBeginMintModal(false);
              setShowSelectFeeRateModal(true);
            }}>
              Next
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showSelectFeeRateModal} onHide={() => setShowSelectFeeRateModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>Choose Confirmation Speed</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body p-4">
            <p>
              Select a fee rate for your mint
            </p>
            <p>
              <b>{mintFeeRate} sat/vbyte</b> {mintFeeRate === RECOMMENDED_FEE_RATE ? '(recommended)' : ''}
            </p>
            <Form.Range min="5" max="60" defaultValue={mintFeeRate} onChange={(evt) => setMintFeeRate(evt.target.value)} />
            <p>
              <b>Total Price:</b> {totalPriceBtcDisplay()}
            </p>
            <p>
              (Minting {numToMint} {config.name}{numToMint === 1 ? '' : 's'})
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              setShowSelectFeeRateModal(false)
              setShowBeginMintModal(true)
            }}>
              Back
            </Button>
            <Button variant="primary" onClick={() => {
              setShowSelectFeeRateModal(false);
              setShowPromptForAddressModal(true);
            }}>
              Next
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showPromptForAddressModal} onHide={() => setShowPromptForAddressModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>Choose a destination</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body p-4">
            <div>
              Where would you like to receive your mint?
            </div>
            <Container className="d-flex flex-column pt-2">
              <div>
                <Button variant="primary" className="my-2 w-50" onClick={() => {
                  setShowPromptForAddressModal(false)
                  setShowCustomAddressModal(true)
                }}>Bitcoin Address</Button>
              </div>
              <div>
                <Button variant="primary" className="my-2 w-50" onClick={async () => {
                  if (!window.nostr) {
                    alert("Oops! It looks like you haven't set up your Nostr key yet. Make sure you have Alby browser extension installed AND have gone into the Alby settings and created a Nostr key. See the Discord's #set-up-wallet channel for screenshots.")
                    return
                  }
                  const npub = await window.nostr.getPublicKey().catch(err => {
                    alert('Error getting public key from Nostr Wallet. It is recommended to use Alby wallet (getalby.com)')
                    return null
                  })
                  if (!npub) return
                  setNostrPublicKey(npub)
                  const pubkeyBuffer = Buffer.from(npub, 'hex')
                  const address = bitcoin.payments.p2tr({ pubkey: pubkeyBuffer, network: TESTNET ? bitcoin.networks.testnet : bitcoin.networks.bitcoin }).address;
                  setDestinationBtcAddress(address)
                  setCustomAddressUsed(false)
                  setShowPromptForAddressModal(false)
                  setShowConfirmMintModal(true)
                }}>Nosft</Button>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              setShowPromptForAddressModal(false)
              setShowBeginMintModal(true)
            }}>
              Back
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showCustomAddressModal} onHide={() => setShowCustomAddressModal(false)} className="modal py-5">
          <Modal.Header closeButton className="modal-header p-4" >
            <Modal.Title>Enter bitcoin address</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body p-4">
            <div>WARNING: This should be a wallet with coin control dedicated to your ordinals. <a target="_blank" href="https://ordswap.io/">Ordswap</a> is a good option
              <br /><br />If not, you should be an advanced user and know what you're doing, otherwise you risk losing your items.</div><br />
            <InputGroup className="mb-3">
              <Form.Control onChange={handleBtcAddressChange}
                            placeholder="Paste BTC address here"
                            aria-label="Paste BTC address heres"
                            aria-describedby="basic-addon2"
                            isInvalid={!isBtcInputAddressValid}
                            autoFocus
              />
              <Form.Control.Feedback type="invalid">
                <br />That is not a valid {TESTNET ? 'testnet' : 'mainnet'} BTC address
              </Form.Control.Feedback>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              setShowCustomAddressModal(false)
              setShowPromptForAddressModal(true)
            }}>
              Back
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showConfirmMintModal} onHide={() => setShowConfirmMintModal(false)} className="modal py-5">
          <Modal.Header closeButton className="modal-header p-4" >
            <Modal.Title>Confirm mint?</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body p-4">
            <div>
              <b>Number to mint:</b> {numToMint}
              <br /><br />
              <b>Fee rate:</b> {mintFeeRate} sat/vbyte
              <br /><br />
              <b>Total price:</b> {totalPriceBtcDisplay()}
              <br /><br />
              <b>Receive to:</b> {destinationBtcAddress}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              setShowConfirmMintModal(false)
              setShowPromptForAddressModal(true)
            }}>
              Back
            </Button>
            <Button variant="primary" onClick={async () => {
              setShowConfirmMintModal(false);
              setShowAwaitingInvoiceModal(true);
              let response
              try {
                response = await axios.post(`${API_BASE_URL}/v1/inscriptions/collections/mint`,
                    {
                      collection_id: COLLECTION_ID,
                      num_to_mint: numToMint,
                      receive_address: destinationBtcAddress,
                      fee_rate: parseFloat(mintFeeRate),
                    }
                )
              } catch (err) {
                console.log(err)
                console.log(err.message)
                setShowAwaitingInvoiceModal(false)
                setShowErrorModal(true)
                return
              }
              console.log(response)
              const {
                bolt11_invoice,
                mint_attempt_id
              } = response.data
              setInvoiceToPay(bolt11_invoice)
              setInvoiceDetails({
                // description: parsedInvoice.description,
                expiresAt: Date.now() + INVOICE_EXPIRY_MS//new Date(parsedInvoice.expires_at)
              })
              setMintAttemptId(mint_attempt_id)
              setShowAwaitingInvoiceModal(false)
              setShowPayModal(true)
            }}>
              Next
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showAwaitingInvoiceModal} onHide={() => setShowAwaitingInvoiceModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>Getting the {config.name}{numToMint === 1 ? '' : 's'} ready...</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 py-3 center-contents">
            <br /><br />
            <TailSpin stroke="#000000" speed={.75} />
            <br /><br /><br />
          </Modal.Body>
        </Modal>
        <Modal show={showPayModal} onHide={() => setShowPayModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>Pay to mint</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 py-3 center-contents modal-body">
            Minting {numToMint} {config.name}{numToMint === 1 ? '' : 's'} at {mintFeeRate} sat/vbyte for {totalPriceBtcDisplay()}
            <br /><br />
            <Button variant="primary" onClick={async () => {
              if (!window.webln) {
                alert("Looks like you don't have a browser wallet installed... Set up Alby (getalby.com) to pay an invoice with one click!")
                return
              }
              await window.webln.enable()
              const resp = await window.webln.sendPayment(invoiceToPay).catch(err => {
                console.log(err)
                return null
              })
              if (resp && resp.preimage) {
                console.log(resp)
                setShowPayModal(false)
                setShowFinalInfoModal(true)
              }
            }}> Pay ⚡ Now </Button>
            <br /><br />
            <Button className="mx-1 my-1" variant="outline-primary" onClick={() => {
              setShowQrCode(!showQrCode)
            }}> {showQrCode ? 'Hide' : 'Show'} QR Code </Button>
            <Button className="mx-1 my-1" onClick={() => {
              navigator.clipboard.writeText(invoiceToPay)
            }} variant="outline-primary"><FaRegCopy /> Copy Invoice</Button>
            <br /><br />
            {showQrCode ?
                <>
                  <QRCodeSVG size="240" value={`lightning:${invoiceToPay}`} /><br /><br />
                </>
                :
                <></>
            }
            <Countdown date={new Date(invoiceDetails.expiresAt)} renderer={countdownRenderer} />
          </Modal.Body>
        </Modal>
        <Modal show={showAwaitingMintModal} onHide={() => setShowAwaitingMintModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>Minting your {config.name}{numToMint === 1 ? '' : 's'}..</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 py-3 center-contents">
            <br /><br />
            <TailSpin stroke="#000000" speed={.75} />
            <br /><br /><br />
          </Modal.Body>
        </Modal>
        <Modal show={showFinalInfoModal} onHide={() => setShowFinalInfoModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 py-3 center-contents">
            Payment received!
            <br />
            {
              minted ?
                  <>
                  </>
                  :
                  <>
                    Your mint is processing now, keep this window open...
                    <br /><br />
                    <TailSpin stroke="#000000" speed={.75} />
                  </>
            }
            {mintOutpoints.map(it => {
              return (
                  <>
                    {
                      it === null ?
                          <>
                            <br />Mint pending...<br /><br />
                            <TailSpin stroke="#000000" speed={.75} />
                          </>
                          :
                          <a
                              href={`https://ordinals.com/output/${it}`}
                              target="_blank"
                          >
                            {it}
                          </a>

                    }
                    <br /><br />
                  </>
              )
            })}
          </Modal.Body>
        </Modal>
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 py-3 center-contents">
            Oops sorry something went wrong
          </Modal.Body>
        </Modal>
        <Modal show={showNoneAvailableModal} onHide={() => setShowNoneAvailableModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>No {config.title} available</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 py-3">
            Sorry, there are no {config.name}s available to mint right now! Check back later, or hop in the discord for updates.
            <br /><br />
            {liveCollectionInfo()}
          </Modal.Body>
        </Modal>
        <Modal show={showViewInfoModal} onHide={() => setShowViewInfoModal(false)} className="py-5">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>{config.title} Collection Info</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 py-3">
            {liveCollectionInfo()}
          </Modal.Body>
        </Modal>
      </>
  )
}

export default App;
